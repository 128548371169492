export default /* glsl */`
varying vec2 vUv;

void main(void) {
  vUv = uv;

  vec4 mvPosition = modelViewMatrix * vec4( position, 1. );

  gl_Position = projectionMatrix * mvPosition;
}
`