<template>
  <div @click.stop="() => {}" class="panel u-bg-white u-black u-pad-x-md u-cursor-default">
    <div class="u-full-height u-relative u-overflow-y-scroll u-invisible-scroll u-pad-y-md">
      <div class="u-row u-left u-middle u-between">
        <div class="panel__arrow u-box-1by1 u-w1of12">
          <svg class="u-wrapper-panel u-fill-gold u-cursor-pointer"  @click="$emit('prev')" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#arrow-left"/>
          </svg>
        </div>
        <app-image class="u-w1of12" :id="data.stCategories[0].image[0].id" />
        <div class="panel__arrow u-w1of12 u-box-1by1">
          <svg class="u-wrapper-panel u-fill-gold u-cursor-pointer" @click="$emit('next')" preserveAspectRatio="xMidYMid meet">
            <use xlink:href="#arrow-right"/>
          </svg>
        </div>
      </div>
      <div class="panel__title u-center u-marg-t-lg t-h2 u-blue">
        {{ data.title }}
      </div>
      <div class="u-marg-t-lg u-pad-x-md t-text--big u-gold u-center">
        {{ data.sousTitre }}
      </div>
      <app-image v-if="data.image.length && !data.video.length" :id="data.image[0].id" class="u-marg-t-lg u-full-width" />
      <video-player
        class="u-marg-t-lg u-full-width"
        v-else-if="data.video.length"
        :poster="data.image.url"
        :src="data.video[0].url"
        :isWrapperPanel="false"
        :muted="false"
      />
      <div class="u-marg-t-md t-text wysiwyg u-blue" v-html="data.wysiwyg" />
      <div v-if="data.CTA && data.CTA.length && data.CTA[0].text" class="u-marg-t-md u-row u-center">
        <app-button :url="data.CTA[0].url" class="t-text--big">
          {{ data.CTA[0].text }}
        </app-button>
      </div>
    </div>
  </div>
</template>

<script>
import AppImage from '@/components/common/AppImage'
import VideoPlayer from '@/components/common/players/VideoPlayer'
import AppButton from '@/components/common/AppButton'

export default {
  props: {
    data: {
      type: Object,
      default: () => { return {} }
    }
  },

  components: {
    AppImage,
    VideoPlayer,
    AppButton
  },

  emits: [['prev', 'next']]
}
</script>

<script setup>
// const props = defineProps({
//   foo: String
// })

defineEmits(['prev', 'next'])
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.panel
  position absolute
  right 0
  top 0
  height 100%
  
  &__arrow
    max-width 35px

  +mq($until: 'tablet')
    top auto
    bottom 0
    height calc( 100% - 130px )
  
  &__cat
    &__image
      width 60px
      height 60px

  &__title
    position relative
    &:after
      content ''
      position absolute
      top calc( 100% + 30px )
      left calc( 50% - 40px )
      height 2px
      width 80px
      background-color: $colors.gold
</style>