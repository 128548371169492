export function polarToCartesian (radius, polar, azimuth = null) {
  if (azimuth === null) {
    return {
      x: radius * Math.cos(polar),
      y: radius * Math.sin(polar)
    }
  }
  else {
    azimuth += Math.PI * .5
    return {
      x: radius * Math.sin(azimuth) * Math.cos(polar),
      y: radius * Math.cos(azimuth),
      z: radius * Math.sin(azimuth) * Math.sin(polar)
    }
  }
}