import { Component } from 'shimmer'
import { Vector3 } from 'three'

import { SunRay } from '@/webGL/objects/SunRay'
import { polarToCartesian, randomRange } from '@/utils/Maths'
import gsap from 'gsap'

const nbrRay = 30
const size = .0015

export class Sun extends Component {
  constructor () {
    super()

    this.wiggleIn = this.wiggleIn.bind(this)
    this.wiggleOut = this.wiggleOut.bind(this)

    this.rayGroups = []
    for (let i = 0 ; i < nbrRay ; i++) {

      const {x, y} = polarToCartesian(randomRange(.7, 1), Math.random() * Math.PI * .5)

      const rays = [
        new SunRay({
          start: new Vector3(0, 0, 0),
          end: new Vector3(x, y, 0),
          width: size
        }),
        new SunRay({
          start: new Vector3(0, 0, 0),
          end: new Vector3(-x, y, 0),
          width: size
        }),
        new SunRay({
          start: new Vector3(0, 0, 0),
          end: new Vector3(x, -y, 0),
          width: size
        }),
        new SunRay({
          start: new Vector3(0, 0, 0),
          end: new Vector3(-x, -y, 0),
          width: size
        })
      ]

      rays.forEach(ray => {
        ray.min = 0
        ray.max = 0
      })

      this.rayGroups.push(rays)
      this.add(...rays)
    }
  }

  wiggleIn () {
    this.rayGroups.forEach(rays => {
      const min = randomRange(.1, 1)
      const max = randomRange(min, 1)
      const duration = randomRange(1, 2)

      rays.forEach(ray => {
        gsap.fromTo(ray, {
          min: 0
        }, {
          min: min,
          duration: duration,
          ease: 'power4.inOut'
        })
        gsap.fromTo(ray, {
          max: 0
        }, {
          max: max,
          duration: duration * 1.5,
          ease: 'power4.inOut'
        })
      })
    })
  }

  wiggleOut () {
    this.rayGroups.forEach(rays => {
      const min = rays[0].min
      const max = rays[0].max
      const duration = randomRange(1, 2)

      rays.forEach(ray => {
        gsap.fromTo(ray, {
          min: min
        }, {
          min: 1,
          duration: duration * 1.5,
          ease: 'power4.inOut'
        })
        gsap.fromTo(ray, {
          max: max
        }, {
          max: 1,
          duration: duration,
          ease: 'power4.inOut'
        })
      })
    })
  }
}

export const sun = new Sun()