import { Component, LoaderManager, pointer } from 'shimmer'

import { BuildingVisible } from '@/webGL/objects/BuildingVisible'
import { Display } from '@/webGL/objects/Display'
import baseData from '@/assets/data'

import {
  AmbientLight,
  DirectionalLight,
  MeshBasicMaterial,
  Vector2,
  Vector3
} from 'three'

export class Building extends Component {
  constructor () {
    super()

    this.onOver = this.onOver.bind(this)
    this.onOut = this.onOut.bind(this)
    this.loadObject = this.loadObject.bind(this)
    this.addLight = this.addLight.bind(this)

    this.wireframe = new BuildingVisible(true)
    this.full = new BuildingVisible()

    this.currentRadius = this.full.radius = this.wireframe.radius = 1
    this.currentMousePos = new Vector3(0, 0, 0)
    this.mousePos = new Vector3(0, 0, 0)
    this.listenToMouse = true
    this.fastSmooth = false

    this.dist = 0
    this.promises = []
    this.radiusInfluence = .2
    this.isLoaded = false
    this.displays = []

    this.fullOpacity = .8
  }

  async loadObject () {
    if (this.isLoaded) {
      return
    }
    this.isLoaded = true
    this.promises.push( LoaderManager.load('total', false).then(obj => {
      this.add(obj[0].object)
    }))
    this.promises.push( LoaderManager.load('interactor', false).then(obj => { 
      this.add(obj[0].object)
    }))

    await Promise.all(this.promises)

    this.bodyguard = this.getByName('bodyguard', false)
    this.bodyguard.material.color.set(0xcccccc)

    this.fullWindows = this.getByName('full_windows', false)
    this.fullWindows.material.color.set(0xcccccc)

    this.inverted = this.getByName('inverse_*', false)
    if (this.inverted.length) {
      this.inverted.forEach(child => {
        this.wireframe.object = child
      })
    }
    this.wireframe.zIndex = 0

    this.fulls = this.getByName('full_structure', false)
    if (this.fulls) {0
      this.full.object = this.fulls
    }
    this.full.zIndex = 1

    this.interactor = this.getByName('interactor')
    this.interactor.on('hover', this.onOver)
    this.interactor.on('out', this.onOut)
    this.interactor.traverse(child => {
      if (child.isMesh) {
        child.transparent = true
        child.material.opacity = 0
      }
    })
  }

  addDisplays (data) {
    baseData.displays.forEach((datum, i) => {
      if (data[i] && data[i].image.length) {
        const display = new Display(data[i].image[0].url, datum)
        // const building = new BuildingVisible()

        const index = this.displays.push({
          building: building,
          display: display
        })
        // building.object = display.obj

        this.add(display)
      }
    })

  }

  addLight () {
    this.ambient = new AmbientLight(0xffffff, 1.5)
    this.add(this.ambient)
  }

  onUpdate () {
    this.radiusTarget = this.radiusInfluence + (this.dist * .05)
    const smooth = this.fastSmooth ? .02 : .01
    this.currentRadius += (this.radiusTarget - this.currentRadius) * smooth

    this.mousePos.x += (this.currentMousePos.x - this.mousePos.x) * .05
    this.mousePos.y += (this.currentMousePos.y - this.mousePos.y) * .05
    this.mousePos.z += (this.currentMousePos.z - this.mousePos.z) * .05

    this.full.radius = this.wireframe.radius = this.currentRadius
    // this.displays.forEach(display => {
    //   display.building.radius = this.currentRadius
    // })
    this.full.visibleCenter.copy(this.mousePos)
    this.wireframe.visibleCenter.copy(this.mousePos)
    // this.displays.forEach(display => {
    //   display.building.visibleCenter.copy(this.mousePos.clone().sub(display.display.obj.position))
    // })
  }

  onOver (event) {
    if (this.listenToMouse) {
      this.currentMousePos.copy(event.intersect)
      this.dist = event.pointer.delta.length()
      this.dist = Math.min(this.dist, 50)
    }
  }

  onOut (event) {
    this.dist = 0
  }

  set target (vector) {
    this.currentMousePos.copy(vector)
  }

  set radius (value) {
    this.radiusInfluence = value * .15
  }

  get radius () {
    return this.radiusInfluence / .15
  }

  set fullOpacity (value) {
    this._opacity = value

    this.full.opacity = value
  }

  get fullOpacity () {
    return this._opacity
  }
}

export const building = new Building()