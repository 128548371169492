<template>
  <div>
    <img src="/images/dior.png" class="home__logo">
    <!-- <div class="t-h2 home__title u-center u-white" v-if="!$device.isMobile">
      Nos actions en faveur des personnes en situation de handicap, à tous les étages
    </div> -->
    <transition-group name="t-fader">
      <template v-for="(tuto, index) in data.stTutorials">
        <popin v-if="introIndex === index" :key="index" class="u-z-back">
          <div class="u-row u-center">
            <categories :noTitle="true" class="u-w8of12" />
          </div>
          <div class="u-marg-t-md u-white-space t-text--big">
            {{ tuto.tutorial }}
          </div>
          <div class="u-marg-t-md u-center">
            <app-button @click="onNextTuto">
              J'ai compris
            </app-button>
          </div>
        </popin>
      </template>
    </transition-group>
    <transition name="t-fader">
      <div v-show="isIntroEnded" class="u-wrapper-panel">
        <transition-group name="t-fader">
          <div v-for="(entry, index) in entries" :key="index">
            <keypoint
              :image="entry.stCategories[0].image[0].id"
              :position="entry.position[0]"
              :to="{...$local('Entry'), params: {slug: entry.slug}}"
              :title="entry.title"
            />
          </div>
        </transition-group>
        <categories :selected="selectedCategories" @toggle="onToggleCategory" class="home__categories" />
      </div>
    </transition>
  </div>
</template>

<script>
import Keypoint from '@/components/Keypoint'

import { FitTo } from 'shimmer'

import { building } from '@/webGL/objects/Building'
import { sun } from '@/webGL/objects/Sun'
import { webGL } from '@/webGL/WebGL'
import gsap from 'gsap'

import Popin from '@/components/common/Popin'
import AppButton from '@/components/common/AppButton'
import Categories from '@/components/Categories'

import { Vector2, Vector3, Box3 } from 'three'

export default {

  components: {
    Popin,
    Keypoint,
    Categories,
    AppButton
  },

  data () {
    return {
      introIndex: -1,
      isIntroEnded: false,
      selectedCategories: []
    }
  },

  computed: {
    data () {
      return this.$store.getters['data/global']
    },
    entries () {
      return this.$store.getters['data/entries']
    }
  },

  created () {
    this.onTransition = true

    this.pos = new Vector2()
    this.currentPos = new Vector2()
    this.deltaPos = new Vector2()
    this.lastPos = new Vector2()

    // this.baseCameraPos = this.$store.state.global.position.clone()
    // this.baseLookPos = this.$store.state.global.look.clone()
  },

  mounted () {
    this.onResize()
    building.listenToMouse = false
    building.target = new Vector3(0, .545, .2)
    building.radius = 20

    this.onIntro()

    this.$store.getters['data/categories'].forEach((cat) => {
      this.selectedCategories.push(cat.id)
    })

    window.addEventListener(this.$device.pointermove, this.onMousemove)
    window.addEventListener(this.$device.pointerdown, this.onPointerDown)
    if (this.$store.getters['global/firstHP']) {
      this.$store.commit('global/firstHP')
    }
  },

  beforeUnmount () {
    if (this.$device.isTouch) {
      // this is cheating to replace correctly look vector
      // this only work because the camera looks right in front of her
      // in front of her = fitTo vector as x and y values as 0
      webGL.camera.look.set(webGL.camera.position.x, webGL.camera.position.y, 0)
    }
    window.removeEventListener(this.$device.pointermove, this.onMousemove)
    window.removeEventListener(this.$device.pointerdown, this.onPointerDown)
  },

  methods: {
    onToggleCategory (id) {
      const index = this.selectedCategories.indexOf(id)
      if (index === -1) {
        this.selectedCategories.push(id)
      }
      else {
        this.selectedCategories.splice(index, 1)
      }

      this.$store.dispatch('data/entries', this.selectedCategories)
    },

    positionCamera () {
      const box = new Box3(new Vector3(-.5, 0, 0), new Vector3(.5, 1.3, 0))
      const { position, look } = FitTo.fit(box, this.$device.isMobile ? .2 : .1, { vector: new Vector3(0, this.$device.isMobile ? 0 : -0.1, 1) })
      this.basePosition = position
      this.baseLook = look
    },

    onResize () {
      this.positionCamera()
    },

    onIntro () {
      if (this.$store.getters['global/firstHP']) {
        sun.wiggleIn()
        const duration = 3

        gsap.to(webGL.camera.position, {
          x: this.basePosition.x,
          y: this.basePosition.y,
          z: this.basePosition.z,
          duration: duration,
          ease: 'power4.inOut'
        })

        gsap.to(webGL.camera.look, {
          x: this.baseLook.x,
          y: this.baseLook.y,
          z: this.baseLook.z,
          duration: duration,
          ease: 'power3.inOut',
          onUpdate: () => {
            webGL.camera.forceUpdateLook = true
          },
          onComplete: () => {
            this.introIndex = 0
          }
        })
      }
      else {
        const duration = 2
        if (!this.$device.isTouch) {
          building.radius = 1
        }
        else {
          building.radius = 0
        }

        gsap.to(webGL.camera.position, {
          x: this.basePosition.x,
          y: this.basePosition.y,
          z: this.basePosition.z,
          duration: duration,
          ease: 'power4.inOut'
        })

        gsap.to(webGL.camera.look, {
          x: this.baseLook.x,
          y: this.baseLook.y,
          z: this.baseLook.z,
          duration: duration,
          ease: 'power3.inOut',
          onUpdate: () => {
            webGL.camera.forceUpdateLook = true
          },
          onComplete: () => {
            if (!this.$device.isTouch) {
              building.listenToMouse = true
            }
            this.isIntroEnded = true
          }
        })
      }
    },

    onNextTuto () {
      this.introIndex++
      if (this.introIndex === this.data.stTutorials.length) {
        sun.wiggleOut()
        building.fastSmooth = true
        if (!this.$device.isTouch) {
          building.radius = 1
        }
        else {
          building.radius = 0
        }

        setTimeout(() => {
          if (!this.$device.isTouch) {
            building.listenToMouse = true
          }
          building.fastSmooth = false
          this.isIntroEnded = true
        }, 1500)
      }
    },

    onUpdate (time) {
      if (this.isIntroEnded && !this.$route.query.debug) {
        if (!this.$device.isTouch) {
          this.currentPos.x += (this.pos.x - this.currentPos.x) * .02
          // this.currentPos.y += (this.pos.y - this.currentPos.y) * .02

          webGL.camera.position.x = this.basePosition.x + this.currentPos.x * .5
          // webGL.camera.position.y = this.basePosition.y + this.currentPos.y * .5

          webGL.camera.forceUpdateLook = true
        }
        else {
          this.currentPos.x += (this.pos.x - this.currentPos.x) * .2
          this.currentPos.y += (this.pos.y - this.currentPos.y) * .2

          webGL.camera.position.x = this.basePosition.x + this.currentPos.x
          webGL.camera.position.y = this.baseLook.y + this.currentPos.y
        }
      }
    },

    onPointerDown (event) {
      if (this.isIntroEnded) {
        const pos = this.$device.getPointerPosition(event)
        this.lastPos = new Vector2(( pos.x / this.$device.width ) * 2 - 1, - ( pos.y / this.$device.height ) * 2 + 1)
      }
    },

    onMousemove (event) {
      if (this.isIntroEnded) {
        if (this.$device.isDesktop) {
          let pos = this.$device.getPointerPosition(event)
          this.pos = new Vector2(( pos.x / this.$device.width ) * 2 - 1, - ( pos.y / this.$device.height ) * 2 + 1)
        }
        else {
          let pos = this.$device.getPointerPosition(event)
          pos = new Vector2(( pos.x / this.$device.width ) * 2 - 1, - ( pos.y / this.$device.height ) * 2 + 1)
          this.deltaPos = new Vector2(this.lastPos.x - pos.x, this.lastPos.y - pos.y)
          this.pos.x += this.deltaPos.x * .6
          this.pos.y += this.deltaPos.y * .6
          this.lastPos = pos
        }
      }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/viewport-sizer'
@import '~@/styles/settings/mixins/mq'

.home
  &__categories
    position absolute
    bottom $spacings.md
    left $spacings.md
    width clamp(300px, 25%, 400px)

    +mq($until: 'tablet')
      right $spacings.md
      width auto
      
  &__title
    position absolute
    top: $spacings.md
    left 200px
    right 200px

  &__logo
    position absolute
    top: $spacings.md
    left calc(50% - 70px)
    width 140px

    +mq($until: 'tablet')
      top: $spacings.md
      left calc(50% - 50px)
      width 100px
</style>