<template>
  <div style="position: fixed; width: 0; height: 0; overflow: hidden; top: -99px">
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <symbol id="arrow-left" viewBox="0 0 10 11">
          <title>Arrow Left</title>
          <path d="M0.513646 6.25682L8.77543 10.3877C9.33804 10.669 10 10.2599 10 9.6309L10 1.36911C10 0.740092 9.33804 0.33098 8.77544 0.612285L0.513646 4.74318C-0.11001 5.05501 -0.11001 5.945 0.513646 6.25682Z"/>
        </symbol>
        <symbol id="arrow-right" viewBox="0 0 10 11">
          <title>Arrow right</title>
          <path d="M9.48635 6.25682L1.22457 10.3877C0.661957 10.669 -3.23503e-08 10.2599 -5.98455e-08 9.6309L-4.2098e-07 1.36911C-4.48475e-07 0.740092 0.661956 0.33098 1.22456 0.612285L9.48635 4.74318C10.11 5.05501 10.11 5.945 9.48635 6.25682Z"/>
        </symbol>
      </defs>
    </svg>
  </div>
</template>


