import { Vector3 } from 'three'

export default {
	namespaced: true,

	state: {
		firstHP: true,
		position: new Vector3(0, .5, 1.5),
		look: new Vector3(0, .5, 0)
	},

	getters: {
		firstHP(state) {
			return state.firstHP
		}
	},

	mutations: {
		firstHP (state) {
			state.firstHP = false
		}
	}
}