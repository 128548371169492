<template>
  <div class="u-wrapper-panel">
    <div class="entry__exp u-pad-t-md">
      <div class="u-row u-center">
        <transition name="t-fader" v-show="!isTransition">
          <app-button-round :to="$local('Homepage')" class="entry__home">
            <img src="/images/home.png" class="u-fit-contain">
          </app-button-round>
        </transition>
      </div>
    </div>
    <transition name="t-fader">
      <panel v-show="!isTransition" :data="data" class="entry__panel" @prev="onPrev" @next="onNext" />
    </transition>
  </div>
</template>

<script>
import { Vector3 } from 'three'
import gsap from 'gsap'

import { webGL } from '@/webGL/WebGL'
import { building } from '@/webGL/objects/Building'
import Panel from '@/components/common/Panel'
import AppButtonRound from '@/components/common/AppButtonRound'

export default {

  components: {
    Panel,
    AppButtonRound
  },

  data () {
    return {
      isTransition: true
    }
  },

  computed: {
    data () {
      return this.$store.getters['data/entryBySlug'](this.$route.params.slug)
    }
  },

  mounted () {
    this.onResize()
  },

  methods: {
    onResize () {
      this.placeCamera()
    },

    placeCamera () {
      building.listenToMouse = false
      building.target = new Vector3(this.data.position[0].x, this.data.position[0].y, this.data.position[0].z)
      building.radius = 5

      let target = {
        position: new Vector3(),
        look: new Vector3()
      }

      let deltaLook = this.$device.isMobile ? 0 : .15

      const position = new Vector3(
        parseFloat(this.data.position[0].x) + parseFloat(this.data.look[0].x) * .5,
        parseFloat(this.data.position[0].y) + parseFloat(this.data.look[0].y) * .5,
        parseFloat(this.data.position[0].z) + parseFloat(this.data.look[0].z) * .5
      )

      gsap.to(webGL.camera.position, {
        x: position.x,
        y: position.y,
        z: position.z,
        duration: 2,
        ease: 'power4.inOut'
      })

      gsap.to(webGL.camera.look, {
        x: parseFloat(this.data.position[0].x) + deltaLook,
        y: parseFloat(this.data.position[0].y),
        z: parseFloat(this.data.position[0].z),
        duration: 2,
        ease: 'power4.inOut',
        onUpdate: () => {
          webGL.camera.forceUpdateLook = true
        },
        onComplete: () => {
          this.isTransition = false
        }
      })
    },

    onPrev () {
      const index = this.data.index
      this.$router.push({...this.$local('Entry'), params: {slug: this.$store.getters['data/nextEntry'](index).slug} })
    },

    onNext () {
      const index = this.data.index
      this.$router.push({...this.$local('Entry'), params: {slug: this.$store.getters['data/prevEntry'](index).slug} })
    }
  },

  beforeRouteUpdate(to, from, next) {
    this.isTransition = true
    if (to.name.includes('Entry')) {
      setTimeout(() => {
        next()
        requestAnimationFrame(() => {
          this.placeCamera()
        })
      }, 700)
    }
    else {
      next()
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.entry
  &__exp
    position absolute
    top 0
    left 0
    height 100%
    width 50%

    +mq($until: 'tablet')
      width 100%

    +mq($from: 'tablet', $until: 'desktop')
      width calc( 100% - 300px )

    +mq($from: 'wide')
      width calc( 100% - 600px )

  &__panel
    width 50%

    +mq($until: 'tablet')
      width 100%

    +mq($from: 'tablet', $until: 'desktop')
      width 300px

    +mq($from: 'wide')
      width 600px

  // &__home
    
</style>