import { createWebHistory } from 'vue-router'

import Introduction from './views/Introduction'
import Homepage from './views/Homepage'
import Entry from './views/Entry'
import messages from './locales'

const routes = [
  {
    path: '',
    name: 'Homepage',
    component: Homepage,
    meta: {
      isFull: true,
      isWebGL: true
    }
  },
  {
    path: 'introduction',
    name: 'Introduction',
    component: Introduction,
    meta: {
      isFull: true,
      isWebGL: false
    }
  },
  {
    path: ':slug',
    name: 'Entry',
    component: Entry,
    meta: {
      isFull: true,
      isWebGL: true
    }
  }
  // {
  //   path: '/:pathMatch(.*)*',
  //   name: 'NotFound', 
  //   component: NotFound
  // }
]

let langRoutes = []

for (let i = 0; i < routes.length; i++)  {
  for (const locale of Object.keys(messages)) {
    const prefix = locale === 'en' ? '' : locale + '/'
    let route = {...routes[i]}
    route.path = '/' + prefix + route.path
    route.name = prefix + route.name
    route.meta = {
      ...routes[i].meta,
      locale: locale
    }

    langRoutes.push(route)
  }
}

export const router = {
  history: createWebHistory(process.env.BASE_URL || '/'),
  routes: langRoutes
}
