export default /* glsl */`
uniform float uMax;
uniform float uMin;
varying vec2 vUv;

void main( void ) {
  float alpha = vUv.y < uMax && vUv.y > uMin ? 1. : .0;
  // float alpha = vUv.y < uMax ? 1. : .0;
  gl_FragColor = vec4( .83, .74, .3, alpha );
}
`