<template>
  <component :is="to.name ? 'router-link' : 'div'" :to="to" class="button-round u-block" >
    <div class="u-box-1by1 u-bg-white u-shadow u-round u-cursor-pointer" @click="$emit('click')">
      <div class="button-round__wrapper u-wrapper-panel">
        <slot class="u-fit-contain" />
      </div>
    </div>
  </component>
</template>

<script>
import Button from './Button'

export default {
  mixins: [Button]
}
</script>

<style lang="stylus">
.button-round
  width 70px
  
  &__wrapper
    padding 22%
</style>