import { api } from '@/store/interfaces/apiCraft'
// import Vue from 'vue'

export default {
  namespaced: true,

  state: {
    global: {},
    categories: [],
    entries: [],
    loaded: false
  },

  getters: {
    global: state =>{
      return state.global
    },
    categories: state =>{
      return state.categories
    },
    entries: state =>{
      return state.entries
    },
    nextEntry: state => index => {
      index = (index + 1) % state.entries.length
      return state.entries[index]
    },
    prevEntry: state => index => {
      index =  index === 0 ? state.entries.length - 1 : index - 1
      return state.entries[index]
    },
    entryBySlug: state => slug => {
      return state.entries.find(entry => entry.slug === slug)
    },
    loaded: state =>{
      return state.loaded
    },
  },

  actions: {
    async global ({ commit, state }) {
      return new Promise(resolve => {
        const { request, cache } = api.getGlobal()
        // cache.then(res => {
        //   if (res !== null) {
        //     commit('global', res.entry)
        //     commit('categories', res.categories)
        //     commit('loaded', true)
        //     resolve()
        //   }
        // })

        request.then(res => {
          commit('global', res.entry)
          commit('categories', res.categories)
          commit('loaded', true)
          resolve()
        })
      })
    },

    async entries ({ commit, state }, categories = []) {
      return new Promise(resolve => {
        const { request, cache } = api.getEntries(categories)
        cache.then(res => {
          if (res !== null) {
            commit('entries', res)
            commit('loaded', true)
          }
        })

        request.then(res => {
          commit('entries', res)
          commit('loaded', true)
        })
      })
    }
  },

  mutations: {
    loaded (state, payload) {
      state.loaded = payload
    },
    global (state, payload) {
      state.global = payload
    },
    categories (state, payload) {
      state.categories = payload
    },
    entries (state, payload) {
      payload.forEach((entry, index) => {
        entry.index = index
      })
      state.entries = payload
    }
  }
}