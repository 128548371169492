<template>
  <component
    :is="to.name ? 'router-link' : 'div'"
    :to="to"
    ref="root"
    class="keypoint u-cursor-pointer"
    :class="{'is-active': isVisible}"
    @click="$emit('click')"
  >
    <div class="keypoint__name u-center u-black">
      {{ title }}
    </div>
    <div class="keypoint__main u-box-1by1">
      <app-image :id="image" class="keypoint__main__image" />
    </div>
    <div class="keypoint__pulse" :class="{'is-active': !isSelected}"></div>
    <!-- <div class="keypoint__heart"></div> -->
  </component>
</template>

<script>
import Object2d from '@/plugins/Dom3d/mixin2d'
import { webGL } from '@/webGL/WebGL'
import AppImage from '@/components/common/AppImage'


export default {
  components: {
    AppImage
  },

  mixins: [Object2d],

  emits: ['click'],

  created () {
    this._camera = webGL.camera
  },

  props: {
    image: {
      type: String,
      default: false
    },
    isSelected: {
      type: Boolean,
      default: false
    },
    isVisible: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: ''
    },
    to: {
      type: Object,
      default: () => {return {}}
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'
@import '~@/styles/settings/easings'

.keypoint
  border-radius 50%
  display block
  width 18px
  opacity 0
  transition opacity .3s linear

  &__name
    z-index 1000
    pointer-events none
    border-radius 8px
    position absolute
    bottom calc(100% + 10px)
    left 50%
    width 130px
    background-color: $colors.white
    transition: transform .3s ease-in-out-quad, opacity .3s linear
    transform translateX(-50%) translateY(10px)
    opacity 0
    background linear-gradient(264.81deg, #F5BE87 3.43%, #FBDCA0 100%) // dirty but flemme
    font-weight $weights.regular
    font-size 8px
    line-height 10px
    font-weight $weights.medium
    padding 5px 10px

  &:hover
    z-index 1

  &:hover &__name
    transform translateX(-50%) translateY(0)
    opacity 1

  &.is-active
    opacity 1
  
  &__main
    border-radius 50%
    background-color: $colors.black
    width 100%
    
    &__image
      // that is a dirty fix to ignore shitty black border due tu antialisng
      position absolute
      top -1px
      left -1px
      width calc(100% + 2px)
      height calc(100% + 2px)

  // &__heart
  //   position absolute
  //   background: $colors.gold
  //   height 30%
  //   width 30%
  //   top 35%
  //   left 35%
  //   border-radius 50%

  &__pulse
    position absolute
    border solid 2px $colors.gold
    height 200%
    width 200%
    top -50%
    left -50%
    border-radius 50%
    opacity 0
    will-change opacity transform

    &.is-active
      animation 2s ease-in-out-quad infinite pulse



@keyframes pulse
  0%
    transform: translateZ(0) scale(.5)
    opacity 0
  20%
    opacity 1

  100%
    transform: translateZ(0) scale(1)
    opacity 0
</style>