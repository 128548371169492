<template>
  <div class="u-wrapper-panel u-bg-blue">
    <div class="u-wrapper-panel u-row u-center u-middle u-pad-lg u-pad-0--xs">
      <building-svg
        class="introduction__building u-full-width u-full-height"
        :class="{'is-ready': isReady}"
      />
    </div>
    <img class="introduction__logo" src="/images/dior.png">
    <div
      v-for="(intro, i) in data.diorIntroduction"
      :class="{'is-visible': i === step, 'unvisible': i !== step}"
      class="introduction__wording u-wrapper-panel u-row u-center u-middle"
    >
      <div class="u-w4of12 u-w10of12--sm t-h2 u-white">
        <span
          class="introduction__wording__letter"
          v-for="(letter, j) in intro.text"
          v-html="letter"
          :style="{transition: `opacity .4s ${j*.015 + .5}s linear`}"
        />
      </div>
    </div>
    <transition name="t-fader--delayed">
      <div class="u-wrapper-panel u-row u-center u-middle" >
        <div class="u-w6of12 u-w10of12--sm">
          <div class="introduction__title t-h1 u-white u-white-space" :class="{'is-visible': step === 0}">
            <span
              v-for="(letter, i) in data.description"
              v-html="letter"
              class="introduction__title__letter"
              :style="{transition: `opacity 1.5s ${i*.01}s linear`}"
            />
          </div>
          <!-- <div class="u-marg-t-lg u-marg-t-0--sm u-pad-t-lg u-row u-between">
            <div v-for="category in categories" class="u-w3of12 u-w6of12--sm u-marg-t-sm--sm">
              <div class="u-center u-row">
                <app-image class="u-w4of12 u-w3of12--sm" :id="category.image[0].id" />
              </div>
              <div class="t-h2 u-marg-t-sm u-center u-uppercase u-gold u-pad-x-sm">
                {{ category.title }}
              </div>
            </div>
          </div> -->
        </div>  
      </div>
    </transition>
    <transition name="t-fader--delayed">
      <!-- <div v-if="data.diorIntroduction && step !== data.diorIntroduction.length" class="introduction__btn u-center">
        <div @click="step++" class="t-link t-text--big">Skip</div>
      </div> -->
      <div v-if="step === 0" class="introduction__btn u-center">
        <app-button @click="step++" class="t-text--big" :isWhite="true">
          Découvrir nos actions
        </app-button>
      </div>
    </transition>
  </div>
</template>

<script>
import BuildingSvg from '@/components/BuildingSvg'
import { building } from '@/webGL/objects/Building'
import AppImage from '@/components/common/AppImage'
import AppButton from '@/components/common/AppButton'

export default {
  components: {
    BuildingSvg,
    AppImage,
    AppButton
  },

  data () {
    return {
      isReady: false,
      step: -1
    }
  },

  computed: {
    data () {
      return this.$store.getters['data/global']
    },
    categories () {
      return this.$store.getters['data/categories']
    }
  },

  mounted () {
    setTimeout(this.initIntro, 300)
  },

  methods: {
    initIntro () {
      this.isReady = true

      setTimeout(() => {
        this.step = 0
        building.loadObject()
      }, 200)
    }
  },

  watch: {
    loaded () {
      this.initIntro()
    },
    step () {
      if (this.step > 0) {
        building.addLight()

        setTimeout(() => {
          this.$router.push(this.$local('Homepage'))
        }, 500)
      }
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/easings'
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'

.introduction
  &__title
    position relative
    &__letter
      opacity 0

    &.is-visible &__letter
      opacity 1
    // &:after
    //   content ''
    //   position absolute
    //   top calc( 100% + 40px )
    //   left calc( 50% - 40px )
    //   height 2px
    //   width 80px
    //   background-color: $colors.gold

  &__btn
    position absolute
    bottom: $spacings.xl
    left calc(50% - 200px)
    width 400px

    +mq($until: 'tablet')
      bottom: $spacings.md

  &__logo
    position absolute
    top: $spacings.md
    left calc(50% - 70px)
    width 140px

    +mq($until: 'tablet')
      top: $spacings.md
      left calc(50% - 50px)
      width 100px

  &__building
    opacity .15
    stroke-dasharray 2170
    stroke-dashoffset 2170
    transition stroke-dashoffset 5s ease-in-quad

    &.is-ready
      stroke-dashoffset 0
      
    +mq($until: 'tablet')
      width 300%
      left -150%

  &__wording
    &__letter
      font-family futura
      font-weight: $weights.medium
      opacity 0

    &.unvisible  &__letter
      transition opacity .7s linear !important

    &.is-visible &__letter
      opacity 1
</style>