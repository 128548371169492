<template>
  <div>
    <div class="u-row u-between">
      <div
        v-for="category in categories"
        @click="$emit('toggle', category.id)"
        class="categories__cat u-w3of12 u-cursor-pointer"
        :class="{'is-active': selected.length && selected.includes(category.id)}"
      >
        <div class="u-center u-row categories__cat__img">
          <app-image class="u-w8of12" :class="{'u-w8of12--sm': !noTitle}" :id="category.image[0].id" />
        </div>
        <div v-if="!noTitle" class="categories__cat__title u-center u-marg-t-sm u-uppercase u-white u-antialiased u-pad-x-xs">
          {{ category.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppImage from '@/components/common/AppImage'

export default {
  emits: ['toggle'],

  props: {
    selected: {
      type: Array,
      default: () => { return [] }
    },
    noTitle: {
      type: Boolean,
      default: false
    }
  },

  components: {
    AppImage
  },

  computed: {
    categories () {
      return this.$store.getters['data/categories']
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/mixins/viewport-sizer'
@import '~@/styles/settings/variables'
@import '~@/styles/settings/easings'

.categories
  &__cat
    opacity .5
    transition opacity .3s linear
    
    &.is-active
      opacity 1
      
    &__img
      transform scale(1, 1) translateZ(0)
      transition transform .3s ease-in-out-quad
    &:hover &__img
      transform scale(1.1, 1.1) translateZ(0)
    &__title
      font-family futura
      font-weight $weights.bold
      viewportSizer('font-size', 13, 1920, 10px, 15px)
      viewportSizer('line-height', 15, 1920, 12px, 18px)
</style>