<template>
  <div
    id="main"
    ref="main"
    :class="{'is-full': $route.meta.isFull}"
    class="u-full-width u-relative u-overflow-hidden u-antialiased"
  >
    <symbols-svg />
    <div ref="containerWebGL" class="u-wrapper-panel"/>
    <router-view v-slot="{ Component }">
      <transition name="t-fader">
        <component v-if="loaded" :is="Component" />
      </transition>
    </router-view>

    <!-- <div ref="containerCSS3D" class="u-wrapper-panel u-overflow-hidden"/> -->
    <debugger v-if="$route.query.debug" />
  </div>
</template>

<script>
import { OrbitControls, LoaderManager } from 'shimmer'

import App from './App'

import { webGL } from '@/webGL/WebGL'
import { building } from '@/webGL/objects/Building'
import { sun } from '@/webGL/objects/Sun'
import Debugger from '@/components/Debugger'

import SymbolsSvg from '@/components/SymbolsSvg'

import data from '@/assets/data'

import { useRoute } from 'vue-router'
import { FitTo } from 'shimmer'

import {
  // AmbientLight,
  // SphereBufferGeometry,
  // MeshPhongMaterial,
  // Mesh,
  Box3,
  Vector3
} from 'three'

export default {
  name: 'App',

  mixins: [App],

  components: {
    SymbolsSvg,
    Debugger
  },

  computed: {
    loaded () {
      return this.$store.getters['data/loaded']
    }
  },

  created () {
    this.pointerevent = null
    this.isWiggleIn = false

    this.$store.dispatch('data/global').then(() => {
      this.$store.dispatch('data/entries')
      building.addDisplays(this.$store.getters['data/global'].diorDisplays)
    })
  },

  mounted () {
    const box = new Box3(new Vector3(-.5, 0, 0), new Vector3(.5, 1.2, 0))
    const { position, look } = FitTo.fit(box, .5, { vector: new Vector3(0, 0, 1) })
    
    webGL.camera.position.copy(position)
    webGL.camera.look.copy(look)

    building.radius = 20
    webGL.scene.add(building)

    // sun = new Sun()
    webGL.scene.add(sun)
    sun.renderOrder = 10
    // sun.position.z = .2
    sun.position.y = .545

    this.onResize()
  },

  methods: {
    onClick () {
      // sun.wiggleIn()
      // if (!this.isWiggleIn) {
        
      // }
      // else {
      //   sun.wiggleOut()
      //   gsap.fromTo(building, {
      //     radius: 10,
      //   }, {
      //     radius: 1,
      //     duration: 1,
      //     ease: 'power4.inOut'
      //   })
      // }
      // this.isWiggleIn = !this.isWiggleIn
    },
  },

  watch: {
    $route (to, from) {
      if (!from.name) {
        if (!to.query.debug) {
          this.$router.push(this.$local('Introduction'))
        }
        else {
          building.loadObject()
          building.addLight()
        }
      }
    }
  }
}
</script>

<style lang="stylus" src="./styles/theme.styl"/>

<style lang="stylus">
// @import '~@/styles/settings/variables'

#main
  min-height 100vh

  &.is-full
    position fixed
    top .5px
    bottom .5px
    left 0
    right 0
    overflow hidden
    min-height auto

</style>
