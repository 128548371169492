import { Component } from 'shimmer'

import { Vector3, CylinderGeometry, ShaderMaterial, Mesh, CustomBlending, AddEquation, SrcAlphaFactor, OneMinusSrcAlphaFactor } from 'three'

import vertexShader from '@/webGL/shaders/ray.vert.js'
import fragmentShader from '@/webGL/shaders/ray.frag.js'

export class SunRay extends Component {
  constructor ({ start = new Vector3(0, 0, 0), end = new Vector3(1, 0, 0), width = .01}) {
    super()

    this.start = start
    this.end = end
    this.width = width

    this.uniforms = {
      uMax: { type: 'f', value: 1 },
      uMin: { type: 'f', value: 0 }
    }

    // this.delta = this.start.clone().sub(this.end)
    this.delta = this.end.clone().sub(this.start)
    this.angle = 0
    if (this.end.x > this.start.x) {
      // RIGHT
      this.angle = this.delta.angleTo(new Vector3(0, -1, 0))
      this.angle += Math.PI
    }
    else {
      // LEFT
      this.angle = this.delta.angleTo(new Vector3(0, 1, 0))
    }

    this.length = this.delta.length()
    this.deltaCenter = this.delta.clone().multiplyScalar(.5)
    this.deltaCenter.add(this.start)

    this.geometry = new CylinderGeometry(width, width, this.length, 4, 1, true)
    this.material = new ShaderMaterial({
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      transparent: true,
      uniforms: this.uniforms,
      depthWrite: false,
      depthTest: false,
      blending: CustomBlending,
      blendEquation: AddEquation,
      blendSrc: SrcAlphaFactor,
      blendDst: OneMinusSrcAlphaFactor
    })
    this.mesh = new Mesh(this.geometry, this.material)
    this.mesh.rotation.z = this.angle
    this.mesh.position.copy(this.deltaCenter)
    this.mesh.renderOrder = 10

    this.add(this.mesh)
  }

  set max (value) {
    this.uniforms.uMax.value = value
  }

  get max () {
    return this.uniforms.uMax.value
  }

  set min (value) {
    this.uniforms.uMin.value = value
  }

  get min () {
    return this.uniforms.uMin.value
  }

  set alpha (value) {
    this.uniforms.uAlpha.value = value
  }

  get alpha () {
    return this.uniforms.uAlpha.value
  }
}