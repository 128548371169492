import { Component, ToCanvas } from 'shimmer'

import { PlaneGeometry, MeshBasicMaterial, Mesh, TextureLoader, RepeatWrapping  } from 'three'

export class Display extends Component {
  constructor (src, data) {
    super()

    const geo = new PlaneGeometry(data.width, data.height)
    const mat = new MeshBasicMaterial()
    this.obj = new Mesh(geo, mat)

    this.obj.position.set(...data.position)
    this.obj.rotation.set(...data.rotation)
    this.obj.matrixWorldNeedsUpdate = true
    this.obj.updateMatrix()
    this.obj.updateMatrixWorld()
    this.obj.updateWorldMatrix()

    this.texture = new TextureLoader().load(src, tex => {
      let imgRatio = tex.image.width / tex.image.height
      let planeRatio = data.width / data.height

      tex.wrapS = RepeatWrapping
      tex.repeat.x = planeRatio / imgRatio
      tex.offset.x = -0.5 * ((planeRatio / imgRatio) - 1)
    })


    mat.map = this.texture
    mat.needsUpdate = true

    this.add(this.obj)
  }
}