import { Component } from 'shimmer'

// import { Vector3, ShaderMaterial, MeshPhongMaterial } from 'three'
import { Mesh, Vector3, MeshLambertMaterial, MeshNormalMaterial, UniformsUtils, BoxGeometry } from 'three'
// import { MeshVisiblePhongMaterial } from '@/webGL/materials/MeshVisiblePhongMaterial'

import vertexShader from '@/webGL/shaders/visible.vert.js'
import fragmentShader from '@/webGL/shaders/visible.frag.js'

// UniformsUtils.merge

let uniforms = {}

export class BuildingVisible extends Component {
  constructor (isInvert = false) {
    super ()
    this.onBeforeCompile = this.onBeforeCompile.bind(this)

    this.visibleCenter = new Vector3()
    this.invert = isInvert
    this._radius = {
      value: 1
    }
    this.objs = []

    this.material = new MeshLambertMaterial({
      transparent: true,
      depthTest: this.invert ? false : true
    })
    this.material.onBeforeCompile = this.onBeforeCompile

    this._opacity = 1
  }

  onBeforeCompile (shader) {
    uniforms = shader.uniforms = {
      ...shader.uniforms,
      uCenter: {
        value: this.visibleCenter
      },
      uRadius : this._radius,
      uInvert : { value: this.invert },
      uOpacity : { value: this._opacity }
    }

    shader.vertexShader = vertexShader
    shader.fragmentShader = fragmentShader
  }

  set object (obj) {
    this.objs.push(obj)
    this.texture = obj.material.map
    obj.material.dispose()
    obj.material = this.material
    this.material.map = this.texture
    this.material.needsUpdate = true
  }

  set zIndex (value) {
    this.objs.forEach(obj => {
      obj.renderOrder = value
    })
  }

  set radius (value) {
    this._radius.value = value
  }

  get radius () {
    return this._radius.value
  }

  set opacity (value) {
    this._opacity = value
  }

  get opacity () {
    return this._opacity
  }
}